import { type BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
	values: {
		xs: 0,
		sm: 768,
		md: 1024,
		lg: 1348,
		xl: 1536,
	},
};
