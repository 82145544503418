'use client';

import { createTheme } from '@mui/material';

import { breakpoints } from './breakpoints';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './components';

export const theme = createTheme({
	breakpoints,
	palette,
	typography,
	components,
});
