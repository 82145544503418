import { type Components, type Theme } from '@mui/material';

export const OutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
	styleOverrides: {
		input: ({ theme, ownerState }) => ({
			...theme.typography.body2,
			caretColor: theme.palette.custom.dark.highest,

			'&:-webkit-autofill': {
				WebkitBoxShadow: `0 0 0 100px ${theme.palette.custom.light.highest} inset`,
				WebkitTextFillColor: theme.palette.custom.dark.highest,
				backgroundClip: 'content-box',
				caretColor: theme.palette.custom.dark.highest,
			},

			'&:disabled': {
				WebkitTextFillColor: theme.palette.custom.dark.medium,
				border: `1px solid ${theme.palette.custom.light.higher}`,
				borderRadius: theme.typography.pxToRem(5),
			},

			...(ownerState.size === 'small' && {
				padding: theme.spacing(1.25, 2),
				height: theme.typography.pxToRem(28),
			}),

			...(ownerState.multiline && {
				padding: 0,
			}),
		}),

		root: ({ theme }) => ({
			backgroundColor: theme.palette.custom.light.highest,
			color: theme.palette.custom.dark.highest,
			caretColor: theme.palette.custom.dark.highest,
			borderRadius: theme.typography.pxToRem(5),

			'&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
				border: `1px solid ${theme.palette.custom.light.medium}`,
			},

			'.MuiOutlinedInput-notchedOutline, &.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
				border: `1px solid ${theme.palette.custom.light.higher}`,
			},
		}),
	},
};
