import { type PaletteOptions as MuiPaletteOptions } from '@mui/material/styles';

interface CustomColorsPalette {
	highest: string;
	higher: string;
	high: string;
	medium: string;
	low: string;
	lowest: string;
}

interface ComponentColorsPalette {
	border: string;
	overlay: string;
	placeholder: string;
	skeleton: string;
	tableRow: string;
	tableRowTotals: string;
	tableSectionDivider: string;
}

interface UserBadgeColorsPalette {
	bg: string;
	color: string;
	borderColor?: string;
}

interface TableColorsPalette {
	border: string;
}
interface DatepickerColorsPalette {
	border: string;
}

interface InputColorsPalette {
	focusedBg: string;
}

interface DropdownColorsPalette {
	boxShadow: string;
}

interface InteractiveColorsPalette {
	hoverBg: string;
	activeBg: string;
	focusedBorderColor: string;
}

interface CustomColors {
	dark: CustomColorsPalette;
	light: CustomColorsPalette;
	component: ComponentColorsPalette;
	userBadge: UserBadgeColorsPalette;
	table: TableColorsPalette;
	dropdown: DropdownColorsPalette;
	datepicker: DatepickerColorsPalette;
	warningBadge: UserBadgeColorsPalette;
	refundBadge: UserBadgeColorsPalette;
	input: InputColorsPalette;
	interactive: InteractiveColorsPalette;
}

const dark: CustomColorsPalette = {
	highest: '#1D1A20',
	higher: 'rgba(34, 34, 34, 0.8)',
	high: 'rgba(34, 34, 34, 0.6)',
	medium: 'rgba(34, 34, 34, 0.5)',
	low: 'rgba(34, 34, 34, 0.2)',
	lowest: 'rgba(34, 34, 34, 0.1)',
};

const light: CustomColorsPalette = {
	highest: '#FFFFFF',
	higher: '#D9D9D9',
	high: 'rgba(255, 255, 255, 0.6)',
	medium: '#949599',
	low: 'rgba(255, 255, 255, 0.2)',
	lowest: 'rgba(255, 255, 255, 0.1)',
};

const component: ComponentColorsPalette = {
	border: '#BBB7C0',
	overlay: '#F5F6F8',
	placeholder: '#7A757F',
	skeleton: '#DCDFE5',
	tableRow: '#FFF',
	tableRowTotals: '#EFF0F2',
	tableSectionDivider: '#E5E1E9',
};

const primaryMain = '#000000';
const primaryText = '#FFFFFE';

const secondaryMain = '#894EEC';
const secondaryLight = '#D5B8F9';
const secondaryText = '#FFFFFE';

const warningMain = primaryMain;
const divider = component.border;
const successMain = '#00B951';
const successDark = '#176341';
const errorMain = '#FC5662';
const errorDark = '#3A1924';
const infoMain = '#0073FF';

declare module '@mui/material/styles' {
	interface Palette {
		custom: CustomColors;
	}

	interface PaletteOptions {
		custom: CustomColors;
	}
}

export const palette: MuiPaletteOptions = {
	mode: 'light',
	primary: {
		main: primaryMain,
		contrastText: primaryText,
	},
	secondary: {
		light: secondaryLight,
		main: secondaryMain,
		contrastText: secondaryText,
	},
	background: {
		default: '#F5F6F8',
		paper: '#FFFFFF',
	},
	divider,
	success: {
		main: successMain,
		dark: successDark,
	},
	error: {
		main: errorMain,
		dark: errorDark,
	},
	info: {
		main: infoMain,
	},
	warning: {
		main: warningMain,
	},
	custom: {
		light,
		dark,
		component,
		userBadge: {
			bg: '#FFE6AD',
			color: '#B3900C',
		},
		table: {
			border: '#F3EFF7',
		},
		dropdown: {
			boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.25)',
		},
		datepicker: {
			border: '#E5E1E9',
		},
		warningBadge: {
			bg: '#FEF5E3',
			color: '#B3900C',
		},
		refundBadge: {
			bg: '#FFEDEC',
			color: '#B64149',
			borderColor: '#FFD6D4',
		},
		input: {
			focusedBg: '#F2F3F5',
		},
		interactive: {
			hoverBg: '#F2F3F5',
			activeBg: '#EAEBED',
			focusedBorderColor: '#894EEC',
		},
	},
};
