import { type Components, type Theme } from '@mui/material';

export const CssBaseline: Components<Theme>['MuiCssBaseline'] = {
	styleOverrides: `
		html,
		body {
			height: 100%;
		}

		#__next {
			min-height: 100%;
			display: flex;
			flex-direction: column;
		}
	`,
};
