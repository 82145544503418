'use client';

import { LicenseInfo } from '@mui/x-license';

const MUI_PRO_LICENSE_KEY =
	'b9b7cfcf183ab9ad41a7b35611ee503cTz05Mzc1NixFPTE3NTE3OTUyNDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);
const MuiXLicense = () => null;
export default MuiXLicense;
