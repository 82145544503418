import { type Components, type Theme } from '@mui/material';

import { CssBaseline } from './css-baseline';
import { Button } from './button';
import { OutlinedInput } from './outlined-input';
import { Tooltip } from './tooltip';
import { ButtonBase } from './button-base';

export const components: Components<Theme> = {
	MuiCssBaseline: CssBaseline,
	MuiButton: Button,
	MuiOutlinedInput: OutlinedInput,
	MuiTooltip: Tooltip,
	MuiButtonBase: ButtonBase,
};
