import { type TypographyVariantsOptions as TypographyOptions } from '@mui/material/styles';

import { createPxToRem } from '@shared/utils/mui/create-px-to-rem';

import { interFont } from './fonts';

declare module '@mui/material/styles' {
	interface TypographyVariants {
		userLabel: React.CSSProperties;
		amountLabel: React.CSSProperties;
		weekDayLabel: React.CSSProperties;
		weekDayButton: React.CSSProperties;
		badge: React.CSSProperties;
		messageTitle: React.CSSProperties;
		messageDescription: React.CSSProperties;
		chartTooltipLabel: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		pxToRem?(size: number): string;
		badge?: React.CSSProperties;
		messageTitle?: React.CSSProperties;
		messageDescription?: React.CSSProperties;
		amountLabel?: React.CSSProperties;
		weekDayLabel?: React.CSSProperties;
		weekDayButton?: React.CSSProperties;
		chartTooltipLabel?: React.CSSProperties;
		userLabel?: React.CSSProperties;
	}
}

const pxToRem = createPxToRem();

export const typography: TypographyOptions = {
	fontFamily: interFont.style.fontFamily,
	pxToRem,
	h2: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(28.968),
		fontWeight: '500',
		lineHeight: '124.275%',
		letterSpacing: '-0.724px',
	},
	body1: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(15),
		fontWeight: '500',
		lineHeight: '133%',
		letterSpacing: '-0.375px',
	},
	subtitle1: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13.146),
		fontWeight: '600',
		lineHeight: '137%',
		letterSpacing: '-0.329px',
	},
	subtitle2: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13),
		fontWeight: '500',
		lineHeight: '137%',
		letterSpacing: '-0.329px',
	},
	userLabel: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13),
		fontWeight: '600',
		lineHeight: 1.37,
		letterSpacing: '-0.329px',
	},
	amountLabel: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(20),
		fontWeight: '500',
		lineHeight: 1.4,
		letterSpacing: '-0.4px',
	},
	weekDayLabel: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13),
		fontWeight: '400',
		lineHeight: 1.37,
		letterSpacing: '-0.329px',
	},
	weekDayButton: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(15),
		fontWeight: '400',
		lineHeight: 1.34,
		letterSpacing: '-0.375px',
	},
	badge: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(11.522),
		fontWeight: '600',
		lineHeight: '138.87%',
		letterSpacing: '-0.288px',
	},
	messageTitle: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(17.115),
		fontWeight: '600',
		lineHeight: '128.541%',
		letterSpacing: '-0.428px',
	},
	messageDescription: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13.146),
		fontWeight: '500',
		lineHeight: '136.928%',
		letterSpacing: '-0.329px',
	},
	chartTooltipLabel: {
		fontFamily: interFont.style.fontFamily,
		fontSize: pxToRem(13),
		fontWeight: '400',
		lineHeight: 1.37,
		letterSpacing: '-0.329px',
	},
};
