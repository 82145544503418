import { type Components, type Theme } from '@mui/material';

export const ButtonBase: Components<Theme>['MuiButtonBase'] = {
	defaultProps: {
		/**
		 * We have disabled the ripple effect to improve performance on low-end devices:
		 * https://mui.com/material-ui/getting-started/faq/#how-can-i-disable-the-ripple-effect-globally
		 */
		disableRipple: true, // No more ripple, on the whole application 💣!
		disableTouchRipple: true,
	},
};
