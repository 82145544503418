import { type Components, type Theme } from '@mui/material';

export const Button: Components<Theme>['MuiButton'] = {
	styleOverrides: {
		root: ({ theme }) => ({
			textTransform: 'none',
			fontWeight: theme.typography.fontWeightBold,
			boxShadow: 'none',

			'&:hover': {
				boxShadow: 'none',
			},
		}),

		sizeMedium: ({ theme }) => ({
			paddingTop: '11px',
			paddingBottom: '11px',
			fontSize: theme.typography.pxToRem(16),
			lineHeight: 24 / 16,
		}),

		sizeLarge: ({ theme }) => ({
			paddingTop: '15px',
			paddingBottom: '15px',
			fontSize: theme.typography.pxToRem(18),
			lineHeight: 28 / 18,
		}),
	},
};
