import { type Components, type Theme } from '@mui/material';

export const Tooltip: Components<Theme>['MuiTooltip'] = {
	styleOverrides: {
		tooltip: ({ theme }) => ({
			padding: `${theme.spacing(1)} ${theme.spacing(1.25)}`,
			background: theme.palette.custom.dark.medium,
			fontSize: theme.typography.pxToRem(14),
			lineHeight: 21 / 14,
		}),

		arrow: ({ theme }) => ({
			color: theme.palette.custom.dark.medium,
		}),
	},
};
