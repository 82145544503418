/**
 * The default font size of the Material Specification.
 *
 * @see https://mui.com/material-ui/customization/typography/#font-size
 */
const defaultMuiFontSize = 14;

/**
 * @see https://github.com/mui/material-ui/blob/master/packages/mui-material/src/styles/createTypography.js#L45
 * @param fontSize base MUI typography font-size, the default value is 14px (see https://mui.com/material-ui/customization/typography/#font-size)
 * @param htmlFontSize html element font-size, 16px is the default font-size used by browsers (see https://mui.com/material-ui/customization/typography/#html-font-size)
 */
export const createPxToRem = (fontSize = defaultMuiFontSize, htmlFontSize = 16) => {
	const coef = fontSize / defaultMuiFontSize;

	return (size: number) => `${(size / htmlFontSize) * coef}rem`;
};
