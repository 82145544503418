'use client';

import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from '@shared/theme/theme';
import { AuthProvider, ReactQueryProvider } from '@entities/auth/providers';
import { AUTH_PAGE_PATH, DASHBOARD_PAGE_PATH } from '@shared/constants';
import MuiXLicense from '@shared/components/mui-x-license';

// TODO move providers according FSD
export const AppProviders: React.FC<React.PropsWithChildren> = ({ children }) => (
	<ThemeProvider theme={theme}>
		<ReactQueryProvider>
			<AppRouterCacheProvider>
				<AuthProvider signedInUrl={DASHBOARD_PAGE_PATH} loggedOutUrl={AUTH_PAGE_PATH}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<MuiXLicense />
						<CssBaseline />
						{children}
					</LocalizationProvider>
				</AuthProvider>
			</AppRouterCacheProvider>
		</ReactQueryProvider>
	</ThemeProvider>
);
